import React from "react"

const Reception = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.95 26.23">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M36.72,26.23H2.23a2.27,2.27,0,0,1,0-4.53h9.21a.4.4,0,1,1,0,.8H2.23A1.43,1.43,0,0,0,.8,23.93a1.45,1.45,0,0,0,1.43,1.5H36.72a1.47,1.47,0,0,0,0-2.93H28.05a.4.4,0,0,1,0-.8h8.67a2.27,2.27,0,0,1,0,4.53Z"
            fill="#010101"
          />
          <path
            d="M25.21,2.75H22.79a.4.4,0,0,1,0-.8h2.42a.55.55,0,0,0,.54-.55.56.56,0,0,0-.54-.6H13.74a.55.55,0,0,0-.55.55.57.57,0,0,0,.55.6h2a.4.4,0,1,1,0,.8h-2A1.36,1.36,0,0,1,12.39,1.4,1.37,1.37,0,0,1,13.74,0H25.21a1.35,1.35,0,0,1,1.34,1.35A1.36,1.36,0,0,1,25.21,2.75Z"
            fill="#010101"
          />
          <path
            d="M20,5.35H18.92a.82.82,0,0,1-.82-.82V2.77A.82.82,0,0,1,18.92,2H20a.82.82,0,0,1,.82.82V4.53A.82.82,0,0,1,20,5.35Zm-1.11-2.6,0,1.78,0,0H20l0-1.78Z"
            fill="#010101"
          />
          <path
            d="M28.05,22.5H10.9a1.58,1.58,0,0,1-1.57-1.57V19.58H29.62v1.35A1.58,1.58,0,0,1,28.05,22.5ZM10.13,20.38v.55a.78.78,0,0,0,.77.77H28.05a.78.78,0,0,0,.77-.77v-.55Z"
            fill="#010101"
          />
          <path
            d="M36.86,20.38H2.09l.06-.45A17.68,17.68,0,0,1,19,4.56,17.69,17.69,0,0,1,36.8,19.93ZM3,19.58H35.94A17,17,0,0,0,19,5.36,16.88,16.88,0,0,0,3,19.58Z"
            fill="#010101"
          />
          <path
            d="M35.41,17.55H3.16a.4.4,0,1,1,0-.8H35.41a.4.4,0,1,1,0,.8Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Reception
