import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const initialSchemaData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Atrium Katowice',
  image: 'https://www.atriumkatowice.pl/static/bg2-min-665c43699a4422ff4a70e00728713b41.jpg',
  '@id': '',
  priceRange: '',
  url: 'https://www.atriumkatowice.pl/',
  description: 'Budynek biurowy w bliskim sąsiedztwie autostrady A4 ➤ Sprawdź!',
  disambiguatingDescription:
    'Atrium składa się z 7 kondygnacji naziemnych oraz dwóch poziomów parkingu podziemnego, który oferuje ponad 170 miejsc parkingowych. Układ pomieszczeń daje duże możliwości aranżacji wnętrz, a przeszklone atrium zapewnia dodatkowe źródło naturalnego światła.',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Katowice',
    addressRegion: 'Województwo śląskie',
    postalCode: '02-677',
    streetAddress: 'Graniczna 54',
    addressCountry: 'PL',
    telephone: '+48 609 880 402 ',
    email: 'agnieszka.radomska@cbre.com ',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    opens: '08:00',
    closes: '18:00',
  },
  sameAs: ['https://atriumkatowice.pl/'],
}

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Atrium Katowice',
  alternateName: 'Atrium Katowice - Powierzchnia do wynajęcia',
  url: 'https://www.atriumkatowice.pl/',
  logo: 'https://www.atriumkatowice.pl/static/bg-0b6c18c3832be5eb102e6d4148944dea.jpg',
  sameAs: 'https://uk.linkedin.com/company/martley-capital-group/',
}

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="pl" />
            <title>{seo.title}</title>
            <meta name="theme-color" content="#000"></meta>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">{JSON.stringify(initialSchemaData)}</script>
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
