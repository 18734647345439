import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { StyledContainer } from "../pages/index";
import Chevron from "./svg/Chevron";
import { icons, seaBlue } from "./variables";

const Standard = ({ data }) => {
  const slides = data.standardSlides.map((e, index) => {
    return (
      <Slide key={index}>
        <IconImg>{icons[index]}</IconImg>
        <StyledParagraph>{e.title}</StyledParagraph>
      </Slide>
    );
  });

  const settings = {
    infinite: true,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          autoplay: true,
          slidesToShow: 5,
          infinite: true,
          speed: 500,
          autoplaySpeed: 1500,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 576,
        settings: {
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          speed: 1000,
          autoplaySpeed: 3000,
          cssEase: "linear",
        },
      },
    ],
  };

  return (
    <StyledSection
      className={"standard"}
      id={`${data.sectionTitle.toUpperCase()}`}
    >
      <StyledContainer>
        <Styledh2>{data.sectionSubtitle}</Styledh2>
        <Styledh3>{data.sectionTitle}</Styledh3>
        <StyledIconsWrapper>{slides}</StyledIconsWrapper>
        <SliderWrapper>
          <OvalArrowLeft>
            <Chevron color="#000" ovalSize="52px" arrowSize="22px" />
          </OvalArrowLeft>

          <StyledSlider {...settings}>{slides}</StyledSlider>
          <OvalArrowRight>
            <Chevron
              color="#000"
              ovalSize="52px"
              arrowSize="22px"
              rotate="180deg"
            />
          </OvalArrowRight>
        </SliderWrapper>
      </StyledContainer>
    </StyledSection>
  );
};

export default React.memo(Standard);

const IconImg = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  svg {
    width: 52px;
    height: 52px;
  }
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div {
    min-width: 182px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const OvalArrowRight = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const OvalArrowLeft = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledSection = styled.section`
  padding: 90px 0;
  background-color: ${seaBlue};
  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;
const Styledh2 = styled.h2`
  text-align: center;
  color: #000;
  font-size: 18px;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const SliderWrapper = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 768px) {
    padding: 20px 0 0 0;
    display: block;
  }
`;

const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 130px;
  margin-bottom: 20px;
`;

const StyledSlider = styled(Slider)``;

const StyledParagraph = styled.p`
  text-align: center;
  margin-top: 20px;
  color: #162030;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
const Styledh3 = styled.h3`
  color: #000;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin: 0 0 65px 0;
  @media screen and (max-width: 768px) {
    font-size: 35px;
    margin: 0 0 15px 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;
